"use client";
/**
 * This page is just for redirecting to the correct page based on the user's local state and search params.
 *
 * It will redirect to the default project if the current project is not found.
 */
import LoadingLayout from "@/components/section/loading-layout";
import { useAuth } from "@/hooks/auth";
import { useProjects } from "@/hooks/project";
import { useAppStore } from "@/store";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { toast } from "sonner";

export const dynamic = "force-dynamic";

function Page() {
  const { data: projects } = useProjects();
  const searchParams = useSearchParams();
  const jumpToParse = searchParams.get("target") === "parse";
  const jumpToApiKey = searchParams.get("target") === "api-key";
  const currentProjectId =
    useAppStore((state) => state.projectId) ?? projects[0]?.id;
  const setCurrentProjectId = useAppStore((state) => state.setProjectId);
  const { push } = useRouter();
  const { user } = useAuth();
  const hasIndexPermission = user?.allowed.index;
  const hasExtractionPermission = user?.allowed.extraction;
  useEffect(() => {
    const currentProject = projects.find(
      (project) => project.id === currentProjectId,
    );
    if (currentProject === undefined) {
      toast.warning("Project not found, redirecting to default project.");
      if (jumpToParse) {
        return push(`/project/${projects[0]?.id}/parse/`);
      } else if (jumpToApiKey) {
        return push(`/project/${projects[0]?.organization_id}/api-key/`);
      }
      return push(`/project/${projects[0]?.id}/pipeline/`);
    }
    if (jumpToParse) {
      return push(`/project/${currentProject?.id}/parse/`);
    } else if (jumpToApiKey) {
      return push(`/project/${currentProject?.id}/api-key/`);
    }
    if (hasIndexPermission === false) {
      return push("/landing");
    }
    if (currentProjectId === null) {
      return;
    }
    return push(`/project/${currentProjectId}/pipeline/`);
  }, [
    currentProjectId,
    hasExtractionPermission,
    hasIndexPermission,
    jumpToApiKey,
    jumpToParse,
    projects,
    push,
    setCurrentProjectId,
  ]);
  return <LoadingLayout message="Loading projects..." />;
}

export default Page;
